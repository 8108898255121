(function () {
  'use strict';

  class DiscussionsFlow {
    constructor($mdDialog, $document) {
      let vm = this;
      vm.$mdDialog = $mdDialog;
      vm.body = $document[0].body;
    }

    show(ev, module, node, callback) {
      const vm = this;
      vm.obj = {module_id: module.id, object_type: 'resource_node', object_id: node.id};
      if (node.discussion_id) {
        vm.obj.id = node.discussion_id;
      }
      vm.$mdDialog.show({
        controller: 'DiscussionsFlowCtrl',
        controllerAs: 'discussionsFlow',
        templateUrl: 'components/discussions-flow/discussions-flow.tpl.html',
        parent: angular.element(vm.body),
        targetEvent: ev,
        clickOutsideToClose: true
      })
        .then(callback);
    }
    getObj() {
      const vm = this;
      return vm.obj;
    }
  }

  /**
   * @ngdoc service
   * @name components.service:DiscussionsFlow
   *
   * @description
   *
   */
  angular
    .module('components')
    .service('DiscussionsFlow', DiscussionsFlow);
}());
